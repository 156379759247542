import styled, { css } from 'styled-components';
import { Breakpoint } from '../../enums/breakpoint';

export const Wrapper = styled.div(({theme}) => css`
  margin: 16px auto;

  ${theme.breakpoints.up(Breakpoint.Large)} {
    display: flex;
    justify-content: center;
  }
`);

export const PersonalDetailsWrapper = styled.div(({theme}) => css`
  ${theme.breakpoints.up(Breakpoint.Large)} {
    width: 50%;
    position: relative;
  }
`);

export const PersonalDetails = styled.div(({theme}) => css`
  padding: 0 4px;
  
  ${theme.breakpoints.up(Breakpoint.Large)} {
    padding: 0 16px;
    position: sticky;
    top: 16px;
  }
`);

export const WorkHistory = styled.div(({theme}) => css`
  padding: 0 4px;

  ${theme.breakpoints.up(Breakpoint.Large)} {
    padding: 0 16px;
    width: 50%;
  }
`);

export const About = styled.section`
  margin-top: 24px;
`;

export const History = styled.section`
  margin-top: 24px;
`;

export const Company = styled.section`
  margin-top: 24px;
`;

export const JobTitle = styled.h6`
  margin-top: 24px;
`;

export const Skills = styled.ul`
  margin-top: 36px;
  list-style: none;
  display: inline-flex;
  flex-wrap: wrap;
`;

export const Skill = styled.li(({ theme }) => css`
  background-color: ${theme.palette.secondary.main};
  color: ${theme.palette.primary.dark};
  padding: 6px 8px;
  border-radius: 4px;
  margin-bottom: 8px;
  margin-right: 8px;
`);

export const Social = styled.ul`
  list-style: none;
  display: flex;
  margin-top: 24px;
`;

export const SocialItem = styled.li`
  margin-right: 8px;
`;
