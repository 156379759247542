import styled from 'styled-components';

export const HorizontalLine = styled.div`
  text-align: center;
  position: relative;
  outline: 0;
  border: 0;
  margin-top: 20px;
  overflow: hidden;

  &:before, &:after {
    background-color: ${({ theme }) => theme.palette.divider};
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  &:before {
    right: 20px;
    margin-left: -50%;
  }

  &:after {
    left: 20px;
    margin-right: -50%;
  }
`;
