import styled, { css, DefaultTheme } from 'styled-components';

type Variant = 'text' | 'contained' | 'outlined';
type Size = 'small' | 'medium' | 'large';

const buttonSize = (size: Size | undefined) => {
  switch (size) {
    case 'small':
      return css`
        font-size: 12px;
        height: 30px;
      `;
    case 'large':
      return css`
        font-size: 16px;
        height: 50px;
      `;
    case 'medium':
    default:
      return css`
        font-size: 14px;
        height: 32px;
      `;
  }
};

const buttonVariant = (variant: Variant, theme: DefaultTheme) => {
  switch (variant) {
    case 'contained':
      return css`
        color: ${theme.palette.primary.dark};
        background-color: ${theme.palette.secondary.main};
      `;
    case 'outlined':
      return css`
        color: ${theme.palette.text.primary};
        background-color: transparent;
        border: 1px solid ${theme.palette.divider};
      `;
    case 'text':
    default:
      return css`
        color: ${theme.palette.text.primary};
        background-color: ${theme.palette.background.default};
      `;
  }
};

export type IProps = {
  variant?: Variant;
  size?: Size;
};

export const Button = styled.button<IProps>(({
  theme,
  variant = 'text',
  size,
  disabled = false,
}) => css`
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid transparent;

  &:active {
    border: 1px solid transparent;
  }
  
  &:disabled {
    background: ${theme.palette.primary.main};

    &:hover {
      background: ${theme.palette.primary.main};
      cursor: not-allowed;
    }
  }
  
  ${buttonVariant(variant, theme)}
  ${buttonSize(size)}
`);
