import styled, { css } from 'styled-components';

export const Wrapper = styled.div(({theme}) => css`
  background-color: ${theme.palette.background.default};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`);

export const Content = styled.div`
  flex-grow: 1;
`;
