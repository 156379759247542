import styled, { css } from 'styled-components';

interface IProps {
  fluid?: boolean;
}

export const Container = styled.div<IProps>(({ fluid = false }) => css`
  max-width: 1276px;
  width: 100%;
  margin: auto;
  padding: 0 16px;
  
  ${fluid && css`
    max-width: 100%;
  `}
`);
