import { FC } from 'react';
import { useTheme } from 'styled-components';
import { Wrapper } from './header.styles';
import { Button } from '../form-elements/button';
import { useThemeStyleContext } from '../../contexts/theme-style';
import { ThemeStyle } from '../../enums/theme-style';
import { Moon } from '../icons/common/moon';
import { Sun } from '../icons/common/sun';

export const Header: FC = () => {
  const theme = useTheme();
  const [themeStyle, setThemeStyle] = useThemeStyleContext();

  return (
    <Wrapper>
      <Button size="medium" aria-label="Theme" onClick={() => {
        const style = themeStyle === ThemeStyle.Dark ? ThemeStyle.Light : ThemeStyle.Dark;
        localStorage.setItem('themeStyle', style);
        setThemeStyle(style);
      }}>
        {themeStyle === ThemeStyle.Light ? <Moon width={14} color={theme.palette.common.black}/> :
          <Sun width={14} color={theme.palette.common.white}/>}
      </Button>
    </Wrapper>
  );
};

