import { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Container } from '../../components/container';

const Wrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0;
`;

interface IProps {
  errorCode: number;
}

export const Error: VFC<IProps> = ({errorCode}) => {
  const {t, i18n} = useTranslation('error');
  return (
    <Wrapper>
      <h1>{errorCode}</h1>
      <p>{t(`${errorCode}`)} <a href={`/${i18n.language}`}>{t('go-home')}</a></p>
    </Wrapper>
  );
};
