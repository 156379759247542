import { useEffect, FC } from 'react';
import { useRoutes } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { getRoutes } from './routes';

export const App: FC = () => {
  const {i18n} = useTranslation();

  const element = useRoutes(getRoutes());

  useEffect(() => {
    document.documentElement.lang = i18n.language;
    document.documentElement.dir = i18n.dir();
  }, [i18n, i18n.language]);

  return element;
};
