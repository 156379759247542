import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Wrapper, Content } from './main.styles';
import { Header } from '../../header';

export const Main: FC = () => {
  return (
    <Wrapper>
      <Header />
      <Content>
        <Outlet />
      </Content>
    </Wrapper>
  );
};
