import { DefaultTheme } from 'styled-components';

import { Breakpoint } from '../enums/breakpoint';
import { ThemeStyle } from '../enums/theme-style';

const paletteLight = {
  mode: ThemeStyle.Light,
  common: {
    black: '#000',
    white: '#fff',
  },
  primary: {
    main: '#fff',
    light: '#7f7f7f',
    dark: '#000',
    contrastText: '#000',
  },
  secondary: {
    main: '#fcd535',
    light: '#ded3a9ff',
    dark: '#F0B90B',
    contrastText: '#fff',
  },
  success: {
    main: '#66bb6a',
    light: '#81c784',
    dark: '#388e3c',
    contrastText: '',
  },
  error: {
    main: 'rgba(244,67,54,1)',
    light: 'rgba(231,84,84,0.5)',
    dark: 'rgba(211,47,47,1)',
    contrastText: '',
  },
  warning: {
    main: '#ffa726',
    light: '#ffb74d',
    dark: '#f57c00',
    contrastText: '',
  },
  info: {
    main: '#29b6f6',
    light: '#4fc3f7',
    dark: '#0288d1',
    contrastText: '',
  },
  grey: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#616161',
    A700: '#303030',
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.54)',
    disabled: 'rgba(0, 0, 0, 0.38)',
    hint: 'rgba(0, 0, 0, 0.38)',
  },
  divider: 'rgba(0, 0, 0, 0.12)',
  background: {
    paper: '#fafafa',
    default: '#fff',
    boxShadow: '0 2px 4px rgb(0 0 0 / 0.2)',
  },
  action: {
    active: 'rgba(0, 0, 0, 0.54)',
    hover: '#EBEBEB',
    hoverOpacity: 0.08,
    selected: 'rgba(0, 0, 0, 0.14)',
    disabled: 'rgba(0, 0, 0, 0.26)',
    disabledBackground: 'rgba(0, 0, 0, 0.12)',
  },
};
const paletteDark = {
  mode: ThemeStyle.Dark,
  common: {
    black: '#000',
    white: '#fff',
  },
  primary: {
    main: '#fff',
    light: '#7f7f7f',
    dark: '#000',
    contrastText: '#fff',
  },
  secondary: {
    main: '#fcd535',
    light: '#ded3a9ff',
    dark: '#F0B90B',
    contrastText: '#fff',
  },
  success: {
    main: '#66bb6a',
    light: '#81c784',
    dark: '#388e3c',
    contrastText: '',
  },
  error: {
    main: 'rgba(244,67,54,1)',
    light: 'rgba(231,84,84,0.5)',
    dark: 'rgba(211,47,47,1)',
    contrastText: '',
  },
  warning: {
    main: '#ffa726',
    light: '#ffb74d',
    dark: '#f57c00',
    contrastText: '',
  },
  info: {
    main: '#29b6f6',
    light: '#4fc3f7',
    dark: '#0288d1',
    contrastText: '',
  },
  grey: {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eeeeee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#616161',
    A700: '#303030',
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
  text: {
    primary: '#fff',
    secondary: 'rgba(255, 255, 255, 0.7)',
    disabled: 'rgba(255, 255, 255, 0.5)',
    hint: 'rgba(255, 255, 255, 0.5)',
  },
  divider: 'rgba(255, 255, 255, 0.12)',
  background: {
    paper: '#0B0E11',
    default: '#181A20',
    boxShadow: '0 2px 4px rgb(255 255 255 / 0.1)',
  },
  action: {
    active: '#fff',
    hover: '#303137',
    hoverOpacity: 0.1,
    selected: 'rgba(255, 255, 255, 0.2)',
    disabled: 'rgba(255, 255, 255, 0.3)',
    disabledBackground: 'rgba(255, 255, 255, 0.12)',
  },
};

const breakpointValues: Record<Breakpoint, number> = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};
const breakpointUnit = 'px';
const breakpointStep = 5;

export const createTheme = (themeStyle: ThemeStyle, direction: 'ltr' | 'rtl'): DefaultTheme => {
  const palette = themeStyle === ThemeStyle.Light ? paletteLight : paletteDark;

  return {
    direction,
    palette,
    breakpoints: {
      values: breakpointValues,
      up: (key) => {
        return `@media (min-width:${breakpointValues[key]}${breakpointUnit})`;
      },
      down: (key) => {
        return `@media (max-width:${breakpointValues[key] - breakpointStep / 100}${breakpointUnit})`;
      },
      between: (start, end) => {
        return (
          `@media (min-width:${breakpointValues[start]}${breakpointUnit}) and ` +
          `(max-width:${breakpointValues[end]}${breakpointUnit})`
        );
      },
    },
    zIndex: {
      mobileStepper: 1000,
      speedDial: 1050,
      appBar: 1100,
      drawer: 1200,
      modal: 1300,
      snackbar: 1400,
      tooltip: 1500,
    },
    typography: {
      h1: '96px',
      h2: '60px',
      h3: '48px',
      h4: '34px',
      h5: '24px',
      h6: '20px',
      p: '14px',
    },
  };
};
