import './services/http-client';

import { createRoot } from 'react-dom/client';
import { StrictMode, Suspense, useState, FC } from 'react';
import { ThemeProvider } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import { initI18n } from './services/i18n';
import { reportWebVitals } from './reportWebVitals';
import { createTheme } from './utils/create-theme';
import { ThemeStyleContext } from './contexts/theme-style';
import { ThemeStyle } from './enums/theme-style';
import { GlobalStyle } from './utils/global-style';
import { App } from './app';

import { Loader } from './components/loader';
import { ErrorBoundary } from './components/error-boudary';

const Bootstrap: FC = () => {
  const {i18n} = useTranslation();
  const themeStyleReducer = useState((localStorage.getItem('themeStyle') as ThemeStyle) ?? ThemeStyle.Dark);

  const baseName = window.location.pathname.startsWith(`/${i18n.language}`) ? `${i18n.language}` : '';

  return (
    <ErrorBoundary>
      <StrictMode>
        <ThemeStyleContext.Provider value={themeStyleReducer}>
          <ThemeProvider theme={createTheme(themeStyleReducer[0], i18n.dir())}>
            <GlobalStyle/>
            <BrowserRouter basename={baseName}>
              <Suspense fallback={<Loader/>}>
                <App/>
              </Suspense>
            </BrowserRouter>
          </ThemeProvider>
        </ThemeStyleContext.Provider>
      </StrictMode>
    </ErrorBoundary>
  );
};

(async () => {
  await initI18n();

  const root = createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    <Bootstrap/>,
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
