import { Route } from './enums/route';
import { Main as MainLayout } from './components/layouts/main';
import { Resume } from './pages/resume';
import { Error } from './pages/error';
import { Navigate } from 'react-router-dom';

export const getRoutes = () => {
  return [
    {
      path: Route.Home,
      index: true,
      element: <Navigate to={Route.Resume}/>,
    },
    {
      path: Route.Resume,
      element: <MainLayout/>,
      children: [
        {
          index: true,
          element: <Resume/>,
        },
      ],
    },
    {
      path: '*',
      element: <Error errorCode={404}/>,
    },
  ];
};
