import { FC, useEffect } from 'react';
import { Container } from '../../components/container';
import {
  About,
  Company,
  History, JobTitle,
  PersonalDetails,
  PersonalDetailsWrapper,
  Skill,
  Skills,
  Social,
  SocialItem,
  WorkHistory,
  Wrapper
} from './resume.styles';
import { HorizontalLine } from '../../components/horizontal-line';
import { Linkedin } from '../../components/icons/common/linkedin';
import { Github } from '../../components/icons/common/github';

import companies from '../../data/work-history.json';

const skills: string[] = [
  'Software Design', 'Micro-service architecture', 'Distributed and Scalable Systems', 'Micro-frontend architecture', 'Test Driven Development (TDD)', 'JavaScript', 'Node.js', 'TypeScript', 'React', 'Angular', 'Python', 'Django', 'Databases', 'PostgreSQL', 'NoSQL', 'Event streaming', 'Apache Kafka', 'Version control', 'Git', 'CI/CD', 'Kubernetes', 'Helm Charts', 'ArgoCD', 'AWS', 'WEB3', 'Problem Solving', 'Responsibility/Task Delegation', 'Knowledge Management', 'Project Management', 'People/Team Management', 'Cost Management', 'Agile Methodologies', 'Domain Driven Design (DDD)'
];

export const Resume: FC = () => {
  useEffect(() => {
    document.title = 'Hayk Aghabekyan - Resume';
  }, []);

  return (
    <Container>
      <Wrapper>
        <PersonalDetailsWrapper>
          <PersonalDetails>
            <h2>Hayk Aghabekyan</h2>
            <h4>Senior Software Engineer</h4>
            <HorizontalLine>Skills</HorizontalLine>
            <Skills>
              {skills.map(skill => (
                <Skill key={skill}>{skill}</Skill>
              ))}
            </Skills>
            <HorizontalLine>Languages</HorizontalLine>
            <Skills>
              <Skill>English</Skill>
              <Skill>Armenian</Skill>
              <Skill>Russian</Skill>
            </Skills>

            <Social>
              <SocialItem>
                <a href="https://www.linkedin.com/in/haykaghabekyan/" target="_blank" rel="noreferrer">
                  <Linkedin width={32} />
                </a>
              </SocialItem>
              <SocialItem>
                <a href="https://github.com/haykaghabekyan/" target="_blank" rel="noreferrer">
                  <Github width={32} />
                </a>
              </SocialItem>
            </Social>
          </PersonalDetails>
        </PersonalDetailsWrapper>
        <WorkHistory>
          <HorizontalLine>About</HorizontalLine>
          <About>
            I’m Hayk, an engineering leader with a passion for delivering successful solutions. My experience in software engineering, team leadership and mentoring has enabled me to lead dynamic teams of 10+ engineers in creating and implementing innovative products. I strive to build strong relationships with team members, fostering a culture of collaboration and excellence.
          </About>
          <HorizontalLine>Work History</HorizontalLine>
          <History>
            {
              companies && companies.map(company => (
                <Company key={company.company}>
                  <h5>{company.company}</h5>
                  {
                    company.history && company.history.map(history => (
                      <div key={`${company}${history.title}`}>
                        <JobTitle>{history.title}</JobTitle>
                        <p>{`${history.years.from} - ${history.years.to} | ${history.location}`}</p>
                        <ul>
                          {history.description && history.description.map(desc => (
                            <li key={desc}>{desc}</li>
                          ))}
                        </ul>
                      </div>
                    ))
                  }
                </Company>
              ))
            }
            <HorizontalLine>Education</HorizontalLine>
            <Company key="yerevan state university">
              <h5>Yerevan State University</h5>
              <div>
                <h6>Bachelor Degree</h6>
                <p>{`September 2008 - June 2012 | Yerevan, Armenia`}</p>
                <ul>
                  <li>Informatics and Applied Mathematics</li>
                </ul>
              </div>
            </Company>
          </History>
        </WorkHistory>
      </Wrapper>
    </Container>
  );
};
